import React from "react"
import { Link } from "gatsby"
import { Box, Flex, Image, Text, useColorMode } from "theme-ui"
import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa"

import GoNationLogo from "../images/gonation-long.svg"
import Container from "./Container"
import { footerItems } from "../constants"

const Footer = () => {
  const [mode] = useColorMode()

  // Footer Links (About, Discover A City, Be Discovered, Support Center)
  const footerLinks = (
    <Flex sx={{ justifyContent: "center", flexWrap: "wrap", mb: [4, 4, 5] }}>
      {footerItems.map(itm => (
        <Box
          key={itm.name}
          sx={{
            width: ["100%", "100%", "25%", "25%"],
            textAlign: "center",
            mb: [4, 4, 3],
            fontSize: 2,
            fontWeight: 300,
            a: {
              color: "white",
              textDecoration: "none",
              transition: "all .3s",
              "&:hover": {
                color: "primary",
                transition: "all .3s",
              },
            },
          }}
        >
          <Link sx={{ color: "white" }} to={itm.link}>
            {itm.name}
          </Link>
        </Box>
      ))}
    </Flex>
  )

  const gonationURL = "https://www.gonation.com/"
  // GoNation logo & container
  const footerLogo = (
    <Flex sx={{ justifyContent: "center", mb: [4, 4, 5] }}>
      <Text as="a" target="_blank" rel="noopener noreferrer" href={gonationURL}>
        <Image
          sx={{ width: [125, 200] }}
          alt="GoNation"
          src={GoNationLogo}
        ></Image>
      </Text>
    </Flex>
  )

  // GoNation socials & container
  const facebookURL = "https://www.facebook.com/GoNation/"
  const instagramURL = "https://www.instagram.com/gonation/"
  const twitterURL = "https://twitter.com/gonation"

  const footerSocials = (
    <Flex
      sx={{
        justifyContent: "space-between",
        mb: [4, 4, 5],
        maxWidth: 225,
        marginX: "auto",
        fontSize: 2,
      }}
    >
      <Box>
        <Text
          aria-label="Facebook"
          as="a"
          target="_blank"
          rel="noopener noreferrer"
          href={facebookURL}
        >
          <FaFacebookF size={30} />
        </Text>
      </Box>
      <Box>
        <Text
          aria-label="Instagram"
          as="a"
          target="_blank"
          rel="noopener noreferrer"
          href={instagramURL}
        >
          <FaInstagram size={30} />
        </Text>
      </Box>
      <Box>
        <Text
          aria-label="Twitter"
          as="a"
          target="_blank"
          rel="noopener noreferrer"
          href={twitterURL}
        >
          <FaTwitter size={30} />
        </Text>
      </Box>
    </Flex>
  )

  const footerStyles = {
    padding: 5,
    backgroundColor: mode === "dark" ? "isWhite" : "text__static",
    color: "white",
    borderTop: "3px solid",
    borderColor: "primary",
    my: 3,
    fontWeight: 300,
  }

  return (
    <Box as="footer" sx={footerStyles}>
      <Container width={900}>
        {footerLogo}
        {footerLinks}
        {footerSocials}
        <Box sx={{ textAlign: "center", mb: [4, 4, 5], fontSize: 2 }}>
          <Text>Made with ♡ in Connecticut</Text>
        </Box>
        <Box sx={{ textAlign: "center", mb: [4, 4, 5], fontSize: 2 }}>
          <Text>©GoNation 2021</Text>
        </Box>
      </Container>
    </Box>
  )
}

export default Footer
