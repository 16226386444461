import React from "react"

const Guy = ({ fill = "#20bed6", size = "35px" }) => {
  return (
    <svg
      x="0px"
      y="0px"
      width={size}
      height={size}
      viewBox="251.351 8.354 35.424 39.604"
      enableBackground="new 251.351 8.354 35.424 39.604"
    >
      <g>
        <path
          fill={fill}
          d="M264.367,40.071l4.861-5.629l5.7,3.359l1.423,10.157l5.221-0.604l-1.757-12.606l-6.604-3.879l1.442-7.326
		l1.666,3.082l8.216,0.799c0,0,1.754,0.216,2.217-1.573c0.008-0.043,0.023-0.218,0.023-0.264c0.011-1.89-1.822-2.045-1.822-2.045
		l-6.041-0.586l-3.012-5.723l-12.67,0.008l-4.517,6.632c-0.084,0.122-0.897,1.502,0.384,2.56c0.019,0.018,0.261,0.165,0.288,0.18
		c0.087,0.069,1.64,0.868,2.732-0.604l3.322-4.878h1.484l-1.96,9.427l-3.195,4.942l-10.417,3.01l0.942,4.668L264.367,40.071z"
        />
        <path
          fill={fill}
          d="M273.415,8.354c-2.235,0-4.044,1.735-4.044,3.879s1.809,3.884,4.044,3.884c2.235,0,4.049-1.74,4.049-3.884
		C277.464,10.094,275.649,8.354,273.415,8.354z"
        />
      </g>
    </svg>
  )
}

export default Guy
