import React from "react"
import { Box } from "theme-ui"

const Container = ({ children, width, style }) => {
  return (
    <Box sx={{ maxWidth: width, margin: "auto", ...style }}>{children}</Box>
  )
}

export default Container
