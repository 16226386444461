import * as React from "react"
import Navigation from "./Navigation"
import Footer from "./Footer"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath

  return (
    <div data-is-root-path={isRootPath}>
      <Navigation></Navigation>
      <main>{children}</main>
      <Footer />
    </div>
  )
}

export default Layout
