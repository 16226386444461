import React from "react"
import { Box, Flex, Text, useColorMode, Button, useThemeUI } from "theme-ui"
import { Link } from "gatsby"

import { MdWbSunny } from "react-icons/md"
import { BsMoon } from "react-icons/bs"
import { navItems } from "../constants"
import Guy from "../assets/Guy"

const Navigation = () => {
  const [mode, setMode] = useColorMode()
  const { theme } = useThemeUI()

  const renderNavItems = margin =>
    navItems.map(({ name, to }) => (
      <Box sx={{ mr: margin ? 4 : " " }} key={to}>
        <Link
          activeStyle={{
            color: theme.colors.primary,
          }}
          to={to}
        >
          {name}
        </Link>
      </Box>
    ))

  return (
    <Box as="nav" sx={navWrapper}>
      <Flex sx={{ justifyContent: "space-between" }}>
        <Link to={"/"}>
          <Flex sx={{ alignItems: "center" }}>
            <Guy size={"32px"}></Guy>
            <Text as="h4" sx={navTitle}>
              Newsroom
            </Text>
          </Flex>
        </Link>

        <Box
          sx={{
            alignItems: "center",
            display: ["none", "none", "flex", "flex"],
            width: ["auto", "auto", 429],
            justifyContent: "space-evenly",
          }}
        >
          {renderNavItems()}
        </Box>
        <Button
          aria-label="Color Mode Toggle"
          sx={{
            background: "none",
            svg: {
              fill: "text",
            },
            padding: 0,
            cursor: "pointer",
            lineHeight: "unset",
            span: {
              display: "flex",
            },
          }}
          onClick={() => setMode(mode === "default" ? "dark" : "default")}
        >
          <Text>
            {mode === "default" ? (
              <BsMoon size={30} />
            ) : (
              <MdWbSunny size={30} />
            )}
          </Text>
        </Button>
      </Flex>
      <Flex
        sx={{
          display: ["flex", "flex", "none", "none"],
          justifyContent: "space-evenly",
          textAlign: "center",
          //   borderTop: "3px solid",
          //   borderColor: "primary",
          py: 1,
          mt: 3,
        }}
      >
        {renderNavItems(false)}
      </Flex>
    </Box>
  )
}

export default Navigation

const navWrapper = {
  padding: [3, 3, 4],
  paddingY: [2, 2, 3],
  backgroundColor: "isWhite",
}

const navTitle = {
  fontWeight: 500,
  paddingLeft: 3,
  fontSize: 2,
}
